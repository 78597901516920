/**
 * @module Tagular
 * @description
 */

const pagesMap = [
  { path: '/', value: 'HOME' },
  { path: '/shop', value: 'SHOP' },
  { path: '/shop/internet', value: 'INTERNET' },
  { path: '/shop/internet/fiber-internet', value: 'FIBER INTERNET' },
  { path: '/shop/internet/internet-service-provider', value: 'INTERNET SERVICE PROVIDER' },
  { path: '/shop/internet/high-speed-internet-services', value: 'HIGH SPEED INTERNET SERVICES' },
  { path: '/shop/phone', value: 'PHONE' },
  { path: '/local', value: 'LOCAL' }
];

/**
 * @function getTagularLocation
 * @description
 */
const getTagularLocation = () => {
  const base = new URL( window.location );
  const { pathname } = base;
  const location = pagesMap.filter( ( page ) => page.path === `/${ pathname.split( '/' ).pop() }` )[ 0 ];

  if ( ! location ) {
    return pagesMap[ 0 ].value;
  }

  return location.value;
};

const EVENTS = {
  ElementClicked: 'redventures.usertracking.v3.ElementClicked',
  ElementViewed: 'redventures.usertracking.v3.ElementViewed',
  FieldInputted: 'redventures.usertracking.v3.FieldInputted',
  FormStarted: 'redventures.usertracking.v3.FormStarted',
  FormSubmitted: 'redventures.usertracking.v3.FormSubmitted',
  ProductClicked: 'redventures.usertracking.v3.ProductClicked',
  PageViewed: 'redventures.usertracking.v3.PageViewed',
  ClientErrored: 'redventures.usertracking.v3.ClientErrored',
  CustomerCaptured: 'redventures.integration.v1alpha1.CustomerCaptured',
  Identify: 'core.Identify.v1'
};

/**
 * @function tagularWrapper
 * @description
 */
const tagularWrapper = ( ...args ) => {
  if ( tagular ) tagular( ...args );
};

/**
 * @function elementClicked
 * @description
 */
export const elementClicked = ( data, event ) => {
  const { target = {} } = event;
  const payload = {
    '@type': EVENTS.ElementClicked,
    webElement: {
      elementType: data?.elementType || target?.type?.toUpperCase() || null,
      location: data?.location || getTagularLocation(),
      position: data?.position || null,
      htmlId: data?.htmlID || target?.id || null,
      text: data?.text || target?.innerText || null
    },
    actionOutcome: data?.actionOutcome || null,
    outboundUrl: data?.outboundUrl || null
  };

  tagularWrapper( 'beam', payload );
};

/**
 * @function elementViewed
 * @description
 */
export const elementViewed = ( data, event ) => {
  const { target = {} } = event;
  const payload = {
    '@type': EVENTS.ElementViewed,
    webElement: {
      elementType: data?.elementType || target?.tageName || null,
      location: data?.location || getTagularLocation(),
      position: data?.position || null,
      htmlId: data?.htmlID || target?.id || null,
      text: data?.text || target?.innerText || null
    }
  };

  tagularWrapper( 'beam', payload );
};

/**
 * @function fieldInputted
 * @description
 */
export const fieldInputted = ( data, event ) => {
  const { target = {} } = event;
  const payload = {
    '@type': EVENTS.FieldInputted,
    userInputField: {
      autofilled: target?.autofilled || data?.autofilled || false,
      fieldType: target?.type || data?.fieldtype || null,
      fieldName: target?.name || data?.fieldname || null,
      fieldId: target?.id || data?.fieldid || null,
      fieldLabel: target?.placeholder || data?.fieldlabel || null
    }
  };

  tagularWrapper( 'beam', payload );
};

/**
 * @function formStarted
 * @description
 */
export const formStarted = ( data ) => {
  const payload = {
    '@type': EVENTS.FormStarted,
    formContext: {
      formType: data?.formType || null,
      formName: data?.formName || null,
      formId: data?.formId || null,
      formPosition: data?.formPosition || null
    }
  };

  tagularWrapper( 'beam', payload );
};

/**
 * @function formSubmitted
 * @description
 */
export const formSubmitted = ( data ) => {
  const payload = {
    '@type': EVENTS.FormSubmitted,
    formContext: {
      formType: data?.formType || null,
      formName: data?.formName || null,
      formId: data?.formId || null,
      formPosition: data?.formPosition || null
    }
  };

  tagularWrapper( 'beam', payload );
};

/**
 * @function pageViewed
 * @description
 */
export const pageViewed = () => {
  const payload = {
    '@type': EVENTS.PageViewed
  };

  tagularWrapper( 'beam', payload, true, false );
  tagularWrapper( 'pageView', false ); // The third parameter tells Preamp to not redecision
};

/**
 * @function pageScrolled
 * @description
 */
export const pageScrolled = ( data ) => {
  const payload = {
    '@type': EVENTS.PageScrolled,
    scrollDistance: data?.scrollDistance
  };

  tagularWrapper( 'beam', payload );
};

/**
 * @function clientErrored
 * @description
 */
export const clientErrored = ( data ) => {
  const payload = {
    '@type': EVENTS.ClientErrored,
    errorMessage: data?.errorMessage || null,
    errorType: data?.errorType || '0' // UNKNOWN = 0; BLOCKING = 1; NONBLOCKING = 2;
  };

  tagularWrapper( 'beam', payload );
};

/**
 * @function customerCaptured
 * @description This event is used to capture customer information like email address
 */
export const customerCaptured = ( data ) => {
  const payload = {
    '@type': EVENTS.CustomerCaptured,
    activityDatetime: new Date().toISOString(),
    emailAddress: data?.emailAddress,
    integrationContext: {
      salesflow: 'SITE'
    }
  };

  /*
   The fourth parameter set to false suppresses webContext from being
   attached to the event payload (which beam does OTB). Otherwise, the
   request will error because webContext is not a part of the event schema.
  */
  tagularWrapper( 'beam', payload, false, false );
};

/**
 * @function identify
 * @description This event is used to collect customer identifiers used
 * to build Make DNA profiles
 */
export const identify = ( data ) => {
  const payload = {
    '@type': EVENTS.Identify,
    traits: {
      email: data?.emailAddress
    }
  };

  tagularWrapper( 'beam', payload );
};
