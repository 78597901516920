/**
 * @module Cohesion Event Hooks
 * @description
 */
import { useEffect } from 'react';
import { setCookie } from '@/js/utils/cookie';

/**
 * @function useCohesionEvents
 * @description
 */
export const useCohesionEvents = () => {
  useEffect( () => {
    cohesion( 'monarch:done', ( err, evaluation ) => {
      const { experienceNumber, experienceName } = evaluation?.source?.trafficFlow?.value || {};
      // Set session cookies for use in cart's Preamp audiences.
      // This allows for consistent experiences from MLP to cart.
      setCookie( 'expNumber', experienceNumber, 0 );
      setCookie( 'expName', experienceName, 0 );
    } );
  }, [] );
};
