import { useEffect } from 'react';
import { trackEvent } from '@/js/helpers/tracking';
import { setCookie } from '@/js/utils/cookie';

const Localize = () => {
  useEffect( () => {
    const script = document.createElement( 'script' );
    script.src = 'https://global.localizecdn.com/localize.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild( script );

    const widget = document.createElement( 'script' );

    script.onload = () => {
      widget.async = true;
      widget.defer = true;
      widget.setHTMLUnsafe( `!function(a){if(!a.Localize){a.Localize={};for(var e=["translate","untranslate","phrase","initialize","translatePage","setLanguage","getLanguage","getSourceLanguage","detectLanguage","getAvailableLanguages","untranslatePage","bootstrap","prefetch","on","off","hideWidget","showWidget"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);
      Localize.on("setLanguage", function(languageData) {
        const localizedLoadedEvent = new CustomEvent("localizedTriggered", {detail: {language: languageData.to}});
        document.dispatchEvent(localizedLoadedEvent);
      });

      Localize.initialize({
      key: 'nH1lia9ugiilt',
      rememberLanguage: true
      });` );
    };

    document.body.appendChild( widget );

    trackEvent( {
      action: 'elementViewed',
      data: {
        elementType: 'button',
        position: 'Localize Widget',
        location: window.location.href,
        text: 'Localize widget loaded'
      }
    } );

    return () => {
      document.body.removeChild( script );
      document.body.removeChild( widget );
    };
  }, [] );

  useEffect( () => {
    const localizedTriggeredHandler = () => {
      trackEvent( {
        action: 'elementClicked',
        data: {
          elementType: 'button',
          position: 'Localize Widget',
          location: window.location.href,
          text: event.detail.language
        }
      } );

      // Cart reads this cookie and will load pre-translated if true
      setCookie( 'localizeLanguage', event.detail.language );
    };

    document.addEventListener( 'localizedTriggered', localizedTriggeredHandler );

    return () => {
      document.removeEventListener( 'localizedTriggered', localizedTriggeredHandler );
    };
  }, [] );
};

export default Localize;
