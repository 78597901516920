import { addPageAction } from '@/js/utils/new-relic';
import { trackEvent } from '@/js/helpers/tracking';
import { getCookie, deleteCookie } from '@/js/utils/cookie';
import { persistData } from '@/js/utils/session-storage';
import { isBeforeDate } from '@/js/utils/helpers';

export const isCartHeavy = ( monarchResponse, query ) => {
  if ( monarchResponse === true || query?.heavy === 'cart' ) {
    document.body.setAttribute( 'data-cart-heavy', true );
    addPageAction( 'cartHeavyActive' );

    // Fire Indicator Event
    const data = {
      elementType: 'BUY FLOW',
      position: 'CART HEAVY'
    };

    trackEvent( { action: 'elementViewed', data } );

    return true;
  }
};

export const isPhoneHeavy = ( monarchResponse, query ) => {
  if ( monarchResponse === true || query?.heavy === 'phone' ) {
    document.body.setAttribute( 'data-phone-heavy', true );
    addPageAction( 'phoneHeavyActive' );

    // Fire Indicator Event
    const data = {
      elementType: 'BUY FLOW',
      position: 'PHONE HEAVY'
    };

    trackEvent( { action: 'elementViewed', data } );

    return true;
  }
};

export const isResumeQuote = ( monarchResponse ) => {
  if ( monarchResponse !== true ) return false;

  const quoteId = getCookie( 'quoteId' );

  return quoteId && quoteId.length === 36;
};

// This compares when a users quote was made with a cutoff date we set in Monarch
// If the quote was made before the cutoff date, we delete the quoteId and set a flag in session storage
export const quoteCreatedAfterCutoffDate = ( monarchResponse ) => {
  if ( typeof monarchResponse === 'string' ) {
    const quoteCreated = getCookie( 'quoteCreated' );
    const dateIsBefore = isBeforeDate( quoteCreated, monarchResponse );

    if ( ! dateIsBefore ) {
      deleteCookie( 'quoteId' );
      persistData( 'declinedResume', '1' );
      return true;
    }
    return false;
  }
};

export const updateOLVersion = ( monarchResponse ) => {
  if ( typeof monarchResponse === 'string' ) {
    persistData( 'olVersion', monarchResponse );
    return true;
  }

  persistData( 'olVersion', 'v2' );
  return false;
};
